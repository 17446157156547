.notifications-container {
  position: fixed;
  top: 20px;
  right: 20px;
  max-width: 400px;
  z-index: 1000;
}

.notification {
  background: #ffffff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  border: 1px solid #ccc;
  border-radius: 4px;
  margin-bottom: 10px;
  padding: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: all 0.3s ease;
}

.notification p {
  margin: 0;
}

.notification.success {
  background: #dff0d8;
  border: 1px solid #3c763d;
  color: #3c763d;
}

.notification.error {
  background: #f2dede;
  border: 1px solid #a94442;
  color: #a94442;
}

/* Hide audio controls */
.notification audio {
  display: none;
}