.p-sidebar-content{
    padding: 0;
}
.p-sidebar-close:hover,
.p-sidebar-icon:hover,
.p-link:hover {
  /* Remove any hover styles, e.g., background-color, color, etc. */
  background-color: transparent !important;
  color: inherit !important;
  /* Add more properties as needed to remove the hover effect */
}

.p-sidebar-close:focus,
.p-sidebar-icon:focus,
.p-link:focus {
  /* Remove the focus-visible style */
  outline: none;
  box-shadow: none;
  /* Add more properties as needed to remove the focus effect */
}
.p-sidebar .p-sidebar-header {
    padding: 5px;
}