.p-inputtext:enabled:focus {
  box-shadow: 0 0 0 0 #ffa800;
}

.logininput_dark {
  border-style: solid;
  border-width: 2px;
  border-color: #4a6ba1;
  height: 35px;
  width: 250px;
  padding-left: 20px;
  border-radius: 10px;
  font-weight: bold;
  font-size: 16px;
  background-color: #ffffff;
}
.logininput_light {
  border-style: solid;
  border-width: 2px;
  border-color: #f2f2f2;
  height: 35px;
  width: 250px;
  padding-left: 20px;
  border-radius: 10px;
  font-weight: bold;
  font-size: 16px;
}
.logininput:focus {
  border-width: 2px;
  border-color: #5383d9;
}
