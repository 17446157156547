.App {
  text-align: center;
  overflow: hidden;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.p-column-title {
  width: 100%;
}
body {
  overflow: hidden;
  min-width: 1280px;
  min-height: 720px;
  /* max-width: 1500px; */
}

.p-datatable .p-datatable-thead > tr > th {
  text-align: left;
  /* padding: 1rem 1rem; */
  border: 1px solid #4a6ba138;
  font-weight: 700;
  color: var(--primary-color);
  background: var(--primary-100);
  transition: box-shadow 0.2s;
}
.p-datatable .p-sortable-column .p-sortable-column-icon {
  color: #4a6ba1;
  margin-left: 0.5rem;
}
#input {
  padding: 0;
  padding-left: 10px;
  padding-right: 10px;
  height: 25px;
}
.pi {
  font-size: 12px;
}
.p-dialog .p-dialog-header .p-dialog-header-icon:last-child {
  margin-right: 0;
  color: #fff;
}
.p-datatable .p-datatable-tfoot > tr > td {
  text-align: left;
  padding: 1rem 1rem;
  border: 1px solid #4a6ba138;
  /* border-width: 0 0 1px 0; */
  font-weight: 700;
  color: #4a6ba1;
  background: #edebfa;
}
.p-tabview .p-tabview-nav li.p-highlight .p-tabview-nav-link {
  background: #ffffff;
  border-color: #4a6ba1;
  color: #4a6ba1;
}
.p-tabview .p-tabview-nav .p-tabview-ink-bar {
  background-color: #4a6ba1 !important;
}
.p-tabview .p-tabview-panels {
  padding: 0.5rem;
  background-color: unset !important;
}
.p-datatable .p-datatable-tfoot > tr > td {
  padding: 0.3rem 0.3rem;
}
#HOMETABLE.p-datatable .p-datatable-thead > tr > th {
  text-align: left;
  /* padding: 1rem 1rem; */
  border: 1px solid #4a6ba138;
  font-weight: 700;
  background: #fff2e2;
  color: #cc8925;
  transition: box-shadow 0.2s;
  height: 40px;
}
.p-dropdown .p-dropdown-label.p-placeholder {
  color: #6c757d;
  /* justify-content: center; */
  display: flex;
  align-items: center;
}
.p-dropdown .p-dropdown-label {
  background: transparent;
  border: 0 none;
  /* justify-content: center; */
  display: flex;
  align-items: center;
}
.highlighted-row {
  background-color: #ffe6e6;
}
.p-sidebar .p-sidebar-header {
  padding: 0px !important;
}
.p-datatable.p-datatable-striped .p-datatable-tbody > tr.p-row-odd {
  background: var(--primary-50);
}

input[type="number"] {
  -moz-appearance: textfield; /* Firefox */
  appearance: textfield; /* Standard */
}

/* Remove spinner buttons for Webkit browsers (Chrome, Safari, Edge) */
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.p-datatable-scrollable-table > .p-datatable-thead {
  position: sticky;
  top: 0;
  z-index: 0;
}
